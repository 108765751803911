import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { H2, useToast } from '@salutejs/plasma-web';
import { white } from '@salutejs/plasma-tokens';

import { HeaderTitle } from '../Header/Header';

import { Field } from './types';
import { Form } from './Form';

import { LoginParams } from '@/api/types/login';
import { sendRequest } from '@/api/sendRequest';
import { CLIENT_ROUTES, SERVER_ROUTES } from '@/utils/routes';
import { StrapiError } from '@/api/types/strapi';

const FormWrapper = styled.div`
    margin: 220px auto 0;
    border-radius: 16px;
    background-color: ${white};
    padding: 44px;

    @media (max-width: 600px) {
        margin-top: 16px;
    }

    & form {
        padding: 0;
        gap: 24px;
        max-height: none;
        overflow-y: hidden;

        & > div:last-child {
            margin: 0;
        }
    }
`;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

const FIELDS: Field<keyof LoginParams>[] = [
    {
        element: 'input',
        autoComplete: 'username',
        type: 'text',
        label: 'Введите логин',
        placeholder: 'Логин',
        name: 'identifier',
    },
    {
        element: 'input',
        autoComplete: 'current-password',
        type: 'password',
        label: 'Введите пароль',
        placeholder: 'Пароль',
        name: 'password',
    },
];

export const LoginForm: React.FC = () => {
    const { push } = useRouter();
    const { showToast } = useToast();

    const handleSubmit = (data: Record<keyof LoginParams, string>) =>
        sendRequest({ path: SERVER_ROUTES.login.href, params: data });

    const { mutate, isLoading } = useMutation({
        mutationFn: handleSubmit,
        onSuccess: () => push(CLIENT_ROUTES.root.href),
        onError: (e: StrapiError) => {
            const message = e.status === 400 ? 'Неверные логин или пароль' : 'Что-то пошло не так, попробуйте ещё раз';

            showToast({ text: message, position: 'top', timeout: 2000 });
        },
    });

    return (
        <FormWrapper>
            <StyledHeader>
                <TitleWrapper>
                    <HeaderTitle iconSize={36} short={false} />
                </TitleWrapper>
                <H2 bold>Войдите в свою учётную запись</H2>
            </StyledHeader>
            <Form
                fields={FIELDS}
                submitText="Войти"
                withCancelButton={false}
                handleSubmit={mutate}
                isLoading={isLoading}
            />
        </FormWrapper>
    );
};
