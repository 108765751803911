import React from 'react';

import { Layout } from '@/components/Layout/Layout';
import { LoginForm } from '@/components/Forms/LoginForm';

const LoginPage: React.FC = () => {
    return (
        <Layout isAuthenticated={false} isHeaderVisible={false}>
            <LoginForm />
        </Layout>
    );
};

export default LoginPage;
